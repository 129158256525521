import ServicesTemplate from "../components/ServicesTemplate";
import { ServicesProps } from "../service";

const config: ServicesProps = {
  title: "Digital Media & Broadcasting Services",
  sectionHeader: "Supporting customers throughout the media sector",
  sectionContent:
    "goSystem Digital Media & Broadcasting Services uses the latest cutting-edge techniques to ensure a resilient and reliable service 24/7/365. From content creation to media distribution and broadcasting, goSystem's proven track record gives you the competitive advantage you need in today's rapidly evolving media industry.",
  splitTag: "A new take on media & Broadcasting",
  splitHeading: "Give your business the edge",
  splitContent:
    "Combining proven broadcasting techniques with state-of-the-art technology and new techniques in digital broadcast media gives your business the competitive edge in the new age of digital media. Our team of broadcast engineers and digital media experts keeps your requirements at the forefront of the solutions we provide.",
  splitImg: "Antenna",
  servicesHeading: "Our Digital Media & Broadcasting Services",
  servicesCards: [
    {
      title: "International Distribution",
      body: "Our resilient and reliable media distribution network ensures we deliver high-quality media wherever you want. From Broadcasting Processing to satellite broadcasting from our satellite partners and internet distribution. goSystem's Global Media Distribution Network ensure media delivery is fast and reliable.",
      img: "WORLD_WIDE_WEB",
    },
    {
      title: "Production & Post",
      body: "goSystem Provides scalable and flexible services for your post and production. From cloud and hosting solutions by goSystem Cloud Services, to our Global Media Network and Switching and file distribution ensures your production and post-production team have 24/7 access to the tools they require.",
      img: "CLOUD",
    },
    {
      title: "Audience Engagement",
      body: "We make it easy for you to ensure your audience engages and participates in the content you broadcast. We have the tools to make it easy. From subscription streaming to phone-in viewer engagement services. goSystem makes it quick and easy for your audience to engage with your content.",
      img: "SMARTPHONE_HEART",
    },
  ],
  callToAction:
    "Discover goSystem's Digital Media & Broadcasting Services Today!",
};

export default () => ServicesTemplate(config);
